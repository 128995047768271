import React from "react";

function Title(props) {
  return (
    <div className="flex ml-5 mt-3">
      <div className="text-lg font-bold text-slate-50">{props.reference}</div>
    </div>
  );
}

export default Title;
