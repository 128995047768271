import { toPng } from "html-to-image";
import axios from "axios";
import VersesCanvas from "./VersesCanvas";
import Button from "../common/Button";
import { useRef, useState } from "react";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

var bibleBookData = require("../../data/books.json");

function VersesImgGenerator() {
  const imageRef = useRef(null);
  const [versesData, setVersesData] = useState(null);
  const [versesImageData, setVersesImageData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showPreview, setShowPreview] = useState(true);

  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onSearch = (searchTerm) => {
    setSearchValue(searchTerm);
  };

  const handleGenerateImage = async () => {
    toPng(imageRef.current)
      .then(function (dataUrl) {
        setVersesImageData(dataUrl);
        setShowPreview(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const url = `https://bible-api.com/${searchValue.toLowerCase()}?translation=kjv`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    logEvent(analytics, "get_verses", {
      passage: `${searchValue}`,
    });
    setShowPreview(true);
    const result = await axios(url);
    setVersesData(result.data);
  };
  return (
    <div className="bg-[#E8EBEE] py-4 h-screen">
      {" "}
      <div>
        <h1>Share the Good New in a new way!</h1>
        <p>Currently only support KJV</p>
      </div>
      {/** Search */}
      <div className="flex justify-center pb-4">
        <div className="mt-10 sm:mt-12">
          <form
            onSubmit={handleSubmit}
            className="sm:mx-auto sm:max-w-xl lg:mx-0"
          >
            <div className="sm:flex">
              <div className="min-w-0 flex-1 relative ">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>

                <input
                  id="search"
                  type="search"
                  value={searchValue}
                  onChange={onSearchChange}
                  placeholder="Search"
                  className="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                />

                <div className="dropdown absolute w-full rounded-md mt-2 bg-slate-100">
                  {bibleBookData.books
                    .filter((book) => {
                      const searchTerm = searchValue.toLowerCase();
                      const bookName = book.toLowerCase();

                      return (
                        searchTerm &&
                        bookName.startsWith(searchTerm) &&
                        bookName !== searchTerm
                      );
                    })
                    .slice(0, 10)
                    .map((book) => (
                      <div
                        onClick={() => onSearch(book)}
                        className="dropdown-row py-1 w-full hover:bg-slate-200 cursor-pointer"
                        key={book}
                      >
                        {book}
                      </div>
                    ))}
                </div>
              </div>

              <div className="mt-3 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  className="block w-full rounded-md bg-primary-500 py-3 px-4 font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-transparent px-3 ">
        {versesData !== null && showPreview === true ? (
          <div className="relative">
            <div className="flex justify-center">
              <VersesCanvas
                reference={versesData.reference}
                passRef={imageRef}
                versesArray={versesData.verses}
              />{" "}
            </div>

            <Button
              onClick={handleGenerateImage}
              className="absolute  top-2/4 z-10 right-[50%]"
            >
              Generate Image
            </Button>
          </div>
        ) : null}
        {versesImageData !== null && showPreview === false ? (
          <div className="flex  justify-center ">
            {" "}
            <img src={versesImageData} alt="Bible Verses" />{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default VersesImgGenerator;
