import "./App.css";
import VersesImageGenerator from "./components/VersesImageGenerator";

function App() {
  return (
    <div className="App">
      <VersesImageGenerator></VersesImageGenerator>
    </div>
  );
}

export default App;
