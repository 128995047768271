import React from "react";

function Verses(props) {
  return (
    <div className="flex ml-5">
      <div className="mr-1 text-[0.5rem] text-slate-50">{props.verse}</div>
      <div className="mr-2 flex text-sm text-left text-slate-50">
        {props.text}
      </div>
    </div>
  );
}

export default Verses;
