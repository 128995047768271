import React from "react";
import Title from "../Title";
import Verses from "../Verses";

function VersesCanvas({ passRef, versesArray, reference }) {
  return (
    <div
      className=" max-w-96 sm:w-6/12  lg:w-6/12 bg-transparent"
      ref={passRef}
    >
      <div className="bg-[#24293E]  py-3 rounded-lg pb-3">
        <div className="flex ml-5 mt-3 bg-transparent mb-1">
          <span className="inline-block bg-close h-3 w-3 rounded-full mr-2"></span>
          <span className="inline-block bg-minimize h-3 w-3 rounded-full mr-2"></span>
          <span className="inline-block bg-expand h-3 w-3 rounded-full mr-2"></span>
        </div>
        <Title reference={reference} />
        {versesArray?.map((data, index) => (
          <Verses key={`verses-${index}`} verse={data.verse} text={data.text} />
        ))}
        <p className="w-full text-[#73788a] text-end px-4 text-sm  ">
          Versesnap.com
        </p>
      </div>
    </div>
  );
}

export default VersesCanvas;
